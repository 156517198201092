import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import BusCheckIn from "../views/BusCheckIn.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: () => {
      window.location.href = "https://app.btmt365.com";
    },
  },
  {
    path: "/covid-19/status",
    name: "Covid19Status",
    component: Home,
    beforeEnter: () => {
      window.location.href = "https://v1.btmt365.com/covid-19/status";
    },
  },
  {
    path: "/training-subcontract",
    name: "TrainingSubContract",
    component: Home,
    beforeEnter: () => {
      window.location.href = "https://v1.btmt365.com/training-subcontract";
    },
  },
  {
    path: "/bus-checkin",
    name: "BusCheckIn",
    component: BusCheckIn,
  },
  {
    path: "/bus-checkin/:id",
    name: "BusCheckInWithId",
    component: BusCheckIn,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
