<template>
  <div class="buscheckin">
    <loading
      v-model:active="isLoading"
      :is-full-page="fullPage"
      color="#df0007"
    ></loading>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "BusCheckIn",
  data() {
    return {
      isLoading: true,
      fullPage: true,
    };
  },
  components: {
    Loading,
  },
  computed: {
    getCheckInCode() {
      // We will see what `params` is shortly
      return this.$route.params.id;
    },
  },

  created() {},

  mounted() {
    this.isLoading = true;
    if (this.getCheckInCode) {
      window.location.href =
        "https://v1.btmt365.com/bus-checkin/" + this.getCheckInCode;
    } else {
      window.location.href = "https://v1.btmt365.com/bus-checkin/";
    }
  },
};
</script>
