<template>
  <router-view />
</template>

<style>
#app {
  /* font-family: "DBHelvethaicaX", sans-serif; */
  font-family: "Sukhumvit Set", sans-serif;
}
</style>
