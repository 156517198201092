<template>
  <div class="home">
    <loading
      v-model:active="isLoading"
      :is-full-page="fullPage"
      color="#df0007"
    ></loading>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "Home",
  data() {
    return {
      isLoading: true,
      fullPage: true,
    };
  },
  components: {
    Loading,
  },

  created() {},

  async mounted() {
    this.isLoading = true;
  },
};
</script>
